<template>
  <v-container>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        align="center"
        justify="center"
      >
        <v-card 
          max-width="350" 
          class="elevation-5 text-center pa-5" 
          style="border-radius:20px;"
        >
          <v-img 
            alt="Clinic logo"
            src="@/assets/logo.png"
          />
          <v-card-subtitle class="title pa-1">
            Login
          </v-card-subtitle>
          <v-card-subtitle class="pa-1">
            Efetue o login para poder acessar o sistema.
          </v-card-subtitle>
          <v-text-field
            v-model="username"
            label="Usuário"
            hide-details
          />
          <v-text-field 
            v-model="password"
            label="Senha" 
            type="password"
          />
          <v-card-actions>  
            <v-btn 
              color="primary" 
              block 
              rounded 
              @click="sendLogin()"
            >
              Entrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Login',
  data: () => ({
    username: '',
    password: ''
  }),
  mounted() {
    this.logout()
  },
  methods: {
    ...mapActions('auth', ['login', 'logout']),
    sendLogin() {
      this.login(this)
        .then(() => {
          this.$util.$alert('Login efetuado com sucesso!', 'success')
        })
        .catch((err) => {
          this.$util.$alert(err)
        })
    }
  }
}
</script>